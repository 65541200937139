<template lang="pug">
v-container(v-if="selectedMotivationalReport")

  v-card.pointer(v-if="selectedMotivationalReport && selectedMotivationalReport.motivationalDmp" v-for="dmp in selectedMotivationalReport.motivationalDmp" :key="dmp.id" class="mx-auto mb-1" max-width="400")
    v-card-text.px-1.py-0
      v-row.my-0
          v-col.py-1 {{ dmp.dmpCategory }}
            div
              b {{ dmp.comment }}
          v-col(cols="auto")
              v-btn(x-small fab dark color="blue" @click.native="showFormReport(dmp)")
                  v-icon mdi-plus


  v-dialog(v-if="dialog.addNewDmp" v-model="dialog.addNewDmp" max-width="600")
    v-card(class="mx-auto" max-width="600")
      v-card-title Добавление ДМП
      v-container
        v-select(v-model="newDmp.dmpCategory" :items="dmpCategories" item-text="title" item-value="title" label="Вид ДМП")
        v-text-field(v-model="newDmp.comment" label="Комментарий (напишите категорию товара)")
      v-card-actions
        v-spacer
        v-btn(type="submit" :disabled="!newDmp.dmpCategory" @click="addNewDmp") Добавить

  form-report(v-if="dialog.showFormReport" v-model="dialog.showFormReport" :selectedItem="selectedDmp" :loadedPhotos="dmpReportPhotos" @saveDmpReport="sendDmpReport" @closeReportForm="closeReportForm")
  div.feedback-alert(v-if="succesSend")
    v-alert(type="success") Отчёт успешно отправлен

  v-btn(fixed bottom right :color="'#ff4081'" class="white--text" @click="showAddDmpDialog()")
    span Добавить ДМП
    
  </template>
  
  
  <script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import FormReport from "./FormReport.vue";
  import { motivationalReportService } from "../../../_services/motivationalReport.service";

  export default {
    
    props: [ 'id', 'nid', 'pid' ],

    components: {
      FormReport
    },
  
    data: () => ({

      dialog:{
        addNewDmp: false,
        showFormReport: false
      },
      newDmp: {
        id: 0,
        dmpCategory : '',
        comment:''
      },
      succesSend: false,
      dmpReportPhotos: []
    }),

    mounted(){
      this.loadMotivationalReport({ projectId: this.id, networkId: this.nid, tradePointId: this.pid })
      this.loadCategories()
    },
  
    computed: {
      ...mapState("motivationalReport", ['selectedMotivationalReport', 'selectedDmp', 'dmpCategories', 'motivationalDmps']),
    },
  
    methods: {
      ...mapActions("motivationalReport", ["loadMotivationalReport", "addMotivationalDmp", "loadCategories", 'saveDmpReport']),
      ...mapMutations("motivationalReport", ["setSelectedDmp"]),

      showAddDmpDialog(){
        this.newDmp = {
          id: 0,
          dmpCategory : '',
          comment:''
        },
        this.dialog.addNewDmp = true
      },

      addNewDmp(){
        this.addMotivationalDmp({motivationalDmp: this.newDmp, motivationalReportId: this.selectedMotivationalReport.id})
        this.dialog.addNewDmp = false
      },

      showFormReport(dmp){
        motivationalReportService.loadDmpReport(dmp.id).then(data => {
          this.dmpReportPhotos = data.reportPhotos
          this.setSelectedDmp(dmp)
          this.dialog.showFormReport = true
        })
      },

      closeReportForm(){
        this.dialog.showFormReport = false
      },

      sendDmpReport(report, dmpId){
        this.saveDmpReport({ motivationalDmpId: dmpId, report: report}).then(() => {
          this.succesSend = true
          setTimeout(() => {
            this.succesSend = false;
            this.loadMotivationalReport({ projectId: this.id, networkId: this.nid, tradePointId: this.pid })
          }, 1000);
        })
        this.dialog.showFormReport = false
      }

    }

  }
  </script>
  <style scoped>
  .feedback-alert {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>