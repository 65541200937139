<template lang="pug">
  div
    div(v-if="load" class="red--text") ЖДИТЕ ФОТО Загружаются
    template(v-if="photosLoading.loading")
      v-progress-linear(v-model="photosLoading.loaded" :buffer-value="photosLoading.total")
      div Загружено {{photosLoading.loaded}} из {{photosLoading.total}} байт, {{photosLoading.progress}}%
    template(v-else-if="!photosLoading.loading && photosLoading.error") {{ photosLoading.error }}
    v-row
      v-col
        Carousel(:photos="all")
</template>

<script>
import { config } from '@/_helpers'
import { fileService } from '@/_services'

import Carousel from "./Carousel"

export default {
  props: ['loadphotos', 'photos', 'tmpphotos', 'isDmp'],
  components: {
    Carousel
  },
  data: function() {
    return {
      load: false,
      photosLoading: {
        error: null,
        loading: false,
        progress: 0,
        loaded: 0,
        total: 0
      },
      allDataPhotos: []
    }
  },
  computed: {
    all() {
      return this.mapper(this.photos, this.image, this.remove)
        .concat(this.mapper(this.tmpphotos, this.temp, this.tmpremove))
    }
  },
  watch: {
    loadphotos: function(val) {
      this.photoChanges(val instanceof Array ? val : [val])
    }
  },
  methods: {
    async photoChanges(photos) {
      if (this.load) return;
      if (photos.length) {
        this.load = true;
        this.$emit('loading');
        let callback = (e) => {
          this.photosLoading.loaded = e.loaded
          this.photosLoading.total = e.total

          if (e.lengthComputable) {
            let percentage = Math.round((e.loaded * 100) / e.total);
            this.photosLoading.progress = percentage
          }
        }
        let result = (data) => {
          this.load = false;
          this.$emit('result', data)
        }
        await this.upload(photos, [], callback, result);
      }
    },
    async upload(photos, dataPhotos, callback, result_func) {
      this.photosLoading = { error: null, loading: true, progress: 0, loaded: 0, total: 0 }
      let formData = new FormData()
      formData.append("files[]", photos[0]);
      try {
        const data = await fileService.uploadFiles(formData, callback);
        this.photosLoading = { error: null, loading: false, progress: 0, loaded: 0, total: 0 }
        data.forEach(x => x.dmp = !!this.isDmp);
        if (this.isDmp) {
          dataPhotos.splice(0, dataPhotos.length, ...data)
        } else {
          dataPhotos.push(...data)
        }
        photos.shift();
        if (photos.length != 0) {
          await this.upload(photos, dataPhotos, callback, result_func);
        } else {
          if (this.isDmp) {
            this.allDataPhotos.splice(0, this.allDataPhotos.length, ...dataPhotos)
          } else {
            this.allDataPhotos.push(...dataPhotos)
          }
          result_func(this.allDataPhotos);
        }
      } catch (error) {
        this.photosLoading = { error: error, loading: false, progress: 0, loaded: 0, total: 0 };
      }
    },
    image(img) {
      return config.image(img.uuid)
    },
    temp(img) {
      return config.temp(img.uuid, img.extension)
    },
    mapper(list, func, remove) {
      return list != null ? list.map(a => {
        a['get'] = () => func(a)
        a['remove'] = () => remove(a)
        return a;
      }) : []
    },
    remove(image) {
      this.$emit('remove', image)
    },
    tmpremove(image) {
      fileService.deleteFile(image.photo).then(() => {
        let i = this.tmpphotos.findIndex(item => item.uuid == image.uuid);
        if (i != -1) {
          this.$emit('removeTmp', i)
        }
      })
    }
  }
}
</script>

<style>
  .image-merch { position: relative;cursor: pointer;display: inline-block; }
  .image-merch .v-icon { position: absolute;background: white; border-radius: 30px;right: 0 }
  .image-merch img { max-height: 100px; max-width: 200px }
</style>