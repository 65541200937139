<template lang="pug">
div
  div.feedback-alert(v-if="snackbar.open")
      v-alert(type="error") Не все поля заполнены
  //- div
  //-   v-snackbar(v-model="snackbar.open" :timeout="snackbar.timeout" centered :color="snackbar.type") {{ snackbar.text }}
  //-     template(v-slot:action="{ attrs }")
  //-       v-btn(text v-bind="attrs" @click="snackbar.open = false") Close
  v-dialog( v-bind="$attrs" max-width="600" persistent fullscreen transition="dialog-bottom-transition" )
    v-card
      v-toolbar( dark color="deep-purple accent-4" )
        v-btn( icon dark @click="handleClose" )
          v-icon mdi-close
        v-toolbar-title {{ selectedItem.title }}

      v-card-text
        v-form
          template
            v-divider
            v-text-field( v-model="selectedItem.dmpCategory" label="Категория ДМП" readonly )

          template
            v-divider
            v-file-input( v-model="ompPhoto" accept="image/*" label="ОМП" )
            Photo(:loadphotos="ompPhoto"
                :photos="loadedOmpPhotos"
                :tmpphotos="selectedOmpPhotos"
                :isDmp="true"
                @loading="isLoadDMPPhoto = true"
                @result="ompPhotoChange"
                @remove="removeLoadedPhoto"
                @removeTmp="removeOmpPhoto")

            v-divider
            v-file-input( v-model="dmpPhotos" multiple accept="image/*" label="Загрузите фото ДМП" )
            Photo(:loadphotos="dmpPhotos"
                :photos="loadedDmpPhotos"
                :tmpphotos="selectedDmpPhotos"
                @loading="isLoadDmpPhoto = true"
                @result="dmpPhotosChange"
                @remove="removeLoadedPhoto"
                @removeTmp="removeDmpPhoto")

            v-card-actions
                v-btn( type="submit" :disabled="filledPhotos" @click="sendReport" ) {{ "Сохранить" }}
                v-btn( @click="handleClose" ) Отмена
    
</template>
  
<script>
import Photo from "@/pages/merch/category/Photo.vue"
import { motivationalReportService } from "../../../_services/motivationalReport.service"

export default {
  props: {
    selectedItem: null,
    loadedPhotos: Array
  },

  components: {
    Photo
  },

  data: () => ({
    ompPhoto: [],
    dmpPhotos: [],
    selectedDmpPhotos: [],
    selectedOmpPhotos: [],
    isLoadDmpPhoto: false,
    isLoadOmpPhoto: false,
    snackbar: {
      open: false,
      timeout: 2000,
      text: '',
    },
  }),

  computed: {
    loadedDmpPhotos(){
      return this.loadedPhotos ? this.loadedPhotos.filter(photo => photo.dmp) : []
    },

    loadedOmpPhotos(){
      return this.loadedPhotos ? this.loadedPhotos.filter(photo => !photo.dmp) : []
    },

    filledPhotos() {
      return (this.selectedOmpPhotos.length == 0 && this.loadedOmpPhotos.length == 0) || (this.selectedDmpPhotos.length == 0 && this.loadedDmpPhotos.length == 0)
    },

  },

  methods: {
    handleClose() {
      this.$emit('closeReportForm')
    },

    dmpPhotosChange(data) {
      this.isLoadDMPPhoto = false;
      this.dmpPhotos = []
      this.selectedDmpPhotos = data
    },

    ompPhotoChange(data) {
      if(this.loadedPhotos.filter(x => !x.dmp)[0]) {
        this.removeLoadedPhoto(this.loadedPhotos.filter(x => !x.dmp)[0])
      }
      this.isLoadOmpPhoto = false;
      this.ompPhoto = []
      this.selectedOmpPhotos = data
    },

    removeDmpPhoto(i) {
      this.selectedDmpPhotos.splice(i, 1)
    },

    removeOmpPhoto(i) {
      this.selectedOmpPhotos.splice(i, 1)
    },

    sendReport(){
      this.selectedDmpPhotos.map(el => {
        el.dmp = true
      })
      this.selectedOmpPhotos.map(el => {
        el.dmp = false
      })

      if(this.loadedOmpPhotos.length == 0 && this.selectedOmpPhotos.length == 0){
        this.openSnackbar
        return;
      }
      let report = {
        dmpCategory: this.selectedItem.dmpCategory,
        reportPhoto: this.selectedOmpPhotos.concat(this.selectedDmpPhotos),
      }
      this.$emit('saveDmpReport', report, this.selectedItem.id)
    },

    removeLoadedPhoto(photo){
      let selectedReport = this.selectedItem.motivationalDmpReports.find(report => new Date(Date.parse(report.sendDate)).getDate() == new Date().getDate())
      if(this.selectedItem.motivationalDmpReports && selectedReport){
        motivationalReportService.deleteMotivationalPhoto(selectedReport.id, photo.uuid).then(() => {
          let list = this.loadedPhotos
          let i = list.findIndex(el => el.uuid == photo.uuid)
          if (i != -1) {
            list.splice(i, 1)
          }
        })
      }
    },

    openSnackbar(text, type) {
      this.snackbar.open = true
    },

  }

}
</script>